<template>
  <b-modal
    id="add-modal"
    ref="add-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        {{ paymentType.id ? '複製' : '新增' }}金流商
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        ref="refDataTypeTable"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 名稱 -->
        <b-form-group label-for="paymentName">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                名稱
                <span class="text-danger">*</span>
              </label>
            </div>
          </template>

          <validation-provider
            #default="validationContext"
            name="名稱"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="paymentType.name"
              :state="getValidationState(validationContext)"
              trim
              placeholder="請輸入金流名稱"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <!-- 驅動 -->
        <b-form-group label-for="provider">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                驅動
                <span class="text-danger">*</span>
              </label>
            </div>
          </template>

          <validation-provider
            #default="validationContext"
            name="驅動"
            rules="required"
          >
            <!-- {{ providerOptions }}
            <b-form-input
              id="provider"
              v-model="paymentType.provider"
              :state="getValidationState(validationContext)"
              trim
              placeholder="請輸入驅動代號"
            /> -->

            <v-select
              v-model="paymentType.provider"
              :options="providerOptions"
              :clearable="false"
              placeholder="請選擇驅動代號"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <!-- 金額設定 -->
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center justify-content-between font-weight-bold">
              <div>
                <feather-icon
                  icon="BoxIcon"
                  class="mr-50"
                  style="margin-top: -3px"
                />

                <span style="font-size: '16px'">金額設定</span>
              </div>

              <div
                v-b-tooltip.hover.v-secondary
                title="若金額設定為 0 不設限"
              >
                <b-img
                  :src="$store.state.app.themeImages.infoImg"
                  width="22"
                  alt="金額設定說明"
                />
              </div>
            </div>

            <hr class="mt-50">
          </b-col>

          <!-- 單日限額 -->
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              label-for="daily"
            >
              <!-- :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 4" -->
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    單日限額
                    <span class="text-danger">*</span>
                  </label>
                </div>
              </template>

              <validation-provider
                #default="{ errors }"
                name="單日限額"
                rules="required"
              >
                <b-form-input
                  id="daily"
                  v-model="paymentType.daily"
                  type="number"
                  trim
                  placeholder="請輸入單日限額"
                />
                <small
                  v-if="errors[0]"
                  class="ml-25 text-danger"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- 單月限額 -->
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              label-for="monthly"
            >
              <!-- :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 4" -->
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    單月限額
                    <span class="text-danger">*</span>
                  </label>
                </div>
              </template>

              <validation-provider
                #default="{ errors }"
                name="單月限額"
                rules="required"
              >
                <b-form-input
                  id="monthly"
                  v-model="paymentType.monthly"
                  type="number"
                  trim
                  placeholder="請輸入單月限額"
                />
                <small
                  v-if="errors[0]"
                  class="ml-25 text-danger"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>

      <div>
        <!-- 基本參數 -->
        <div class="mb-1">
          <div class="d-flex align-items-center justify-content-between font-weight-bold">
            <div>
              <feather-icon
                icon="BoxIcon"
                class="mr-50"
                style="margin-top: -3px"
              />

              <span style="font-size: '16px'">參數設定</span>
            </div>
          </div>

          <hr class="mt-50">

          <div
            v-for="(element, index) in paymentType.config"
            :key="`config-${element.key}`"
          >
            <b-form-group
              :label-for="element.key"
              :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 4"
            >
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    {{ element.label }}
                    <small>( {{ element.key }} )</small>
                  </label>
                </div>
              </template>

              <div class="d-flex text-nowrap align-items-center">
                <b-form-input
                  :id="element.key"
                  v-model="element.value"
                  trim
                  :placeholder="`請輸入${element.label}`"
                />

                <div class="d-flex text-nowrap align-items-center">
                  <b-dropdown
                    variant="link"
                    no-caret
                    right
                    toggle-class="p-50"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="selectedEditRow(element, index)"
                    >
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>編輯</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="removeItem(element, index)"
                    >
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/close.svg')"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>移除</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>

        <!-- 新增參數 -->
        <div class="text-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="sm"
            @click="addNewItem"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>新增參數</span>
          </b-button>
        </div>

        <!-- 規則設定 -->
        <payment-rule
          ref="paymentRuleEditModal"
          :config-array="paymentType.config"
          :selected-index="selectedIndex"
          payment-rule-id="payment-rule-edit-modal"
          @edit-rule="editPaymentData"
        />
      </div>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BRow, BCol, BButton, VBTooltip, BImg, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSpinner, BDropdownItem,
  BDropdown,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import PaymentRule from './components/PaymentRule.vue'
import { usePaymentSetting, usePaymentType } from '../usePayment'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BButton,
    BForm,
    BSpinner,
    BFormGroup,
    BFormInput,
    BDropdownItem,
    BDropdown,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
    PaymentRule,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      isBusy: false,
      required,
      selectedIndex: null,
      missingFields: [],
    }
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetPaymentData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('add-modal')
      })
    },

    // (編輯)參數設定
    editPaymentData(item) {
      const resolveData = this.syncObject(this.blankSetting, item)
      if (this.selectedIndex !== null) {
        this.$set(this.paymentType.config, this.selectedIndex, resolveData)
        return
      }
      this.paymentType.config.push(resolveData)
    },

    // (觸發)編輯規則
    selectedEditRow(item, index) {
      if (this.isBusy) return
      this.selectedIndex = index
      setTimeout(() => { this.$refs.paymentRuleEditModal.getData(item) }, 200)
    },

    // (觸發)新增規則
    addNewItem() {
      if (this.isBusy) return
      const resolveData = JSON.parse(JSON.stringify(this.blankSetting))
      this.selectedIndex = null
      this.$refs.paymentRuleEditModal.getData(resolveData)
    },

    // (觸發)移除規則
    removeItem(item, index) {
      if (this.isBusy) return
      this.$swal({
        title: '刪除參數',
        text: `你確定要刪除參數 ${item.label} 嗎?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.paymentType.config.splice(index, 1)
        }
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return
      const requiredFields = {
        name: '金流商名稱',
        provider: '驅動',
        daily: '單日限額',
        monthly: '單月限額',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.paymentType[Object.keys(requiredFields)[i]] === null || this.paymentType[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      if (this.paymentType.config.length <= 0) {
        this.useAlertToast(false, '參數設定不可為空')
        return
      }
      const resolveData = {
        ...this.paymentType,
        daily: Number(this.paymentType.daily),
        monthly: Number(this.paymentType.monthly),
      }

      this.isBusy = true
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.setPaymentTypeCreate({
              ...resolveData,
            })
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetPaymentData()
                this.$nextTick(() => {
                  this.$bvModal.hide('add-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (獲取)資料
    getData(copyItem) {
      const resolveData = {
        ...this.syncObject(this.blankPaymentType, copyItem),
      }
      this.paymentType = resolveData
      this.$bvModal.show('add-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      syncObject,
      providerOptions,
    } = usePaymentSetting()

    const {
      paymentType,
      blankSetting,
      setPaymentTypeCreate,
    } = usePaymentType()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankPaymentType = {
      name: null,
      provider: null,
      monthly: null,
      daily: null,
      config: [],
    }

    paymentType.value = JSON.parse(JSON.stringify(blankPaymentType))

    const resetPaymentData = () => {
      paymentType.value = JSON.parse(JSON.stringify(blankPaymentType))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetPaymentData)

    return {
      paymentType,
      providerOptions,
      blankPaymentType,
      blankSetting,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,

      setPaymentTypeCreate,

      resetPaymentData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  .dropdown-item-btn-image {
    width: 14px;
    height: 14px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-todo-dropdown .8s linear infinite;
    }
  }
}

@keyframes btn-breathe-todo-dropdown {
  0%{ transform: scale(0.8); }
  50%{ transform: scale(1.2); }
  100%{ transform: scale(0.8); }
}
</style>
